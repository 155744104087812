import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Page from '../templates/Page'
import Seo from '../components/seo'
import {
  H2,
  H1,
  Hero,
  Text,
  Container,
  TwoColumnGrid,
  Button,
} from '../utils/styles'
import { breakpoints, colors, space } from '../utils/theme'
import { useInViewportAnimation } from '../utils/hooks'

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: ${({ gap }) => (gap ? gap : space[5])};
  margin: ${space[10]} 0 ${space[10]} 0;

  @media (max-width: ${breakpoints.m}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : space[6])};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

const ArbeitsBeispiele = () => {
  useInViewportAnimation()

  return (
    <Page useContainer={false}>
      <Seo title="Arbeitsbeispiele" />

      <Container>
      <div style={{marginTop: space[20]}}>
        <a href="/unternehmen" target="_self" style={{textDecoration: 'none'}}>
        <Button backgroundColor={colors.grey} style={{ color: 'black'}}>
              Zurück
          </Button>
          </a>
        </div>
        <H2>BEISPIELE UNSERER ARBEIT</H2>
        <PhotoGrid>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRowEnd: 3,
              gridColumnEnd: 7,
            }}
          >
              <StaticImage
                src="../images/beispiel-1.png"
                layout="fullWidth"
                alt="Konstruktionen"
                loading="lazy"
                style={{ height: '100%' }}
              />
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 7,
              gridRowEnd: 3,
              gridColumnEnd: 13,
            }}
          >
            <StaticImage
            src="../images/beispiel-2.png"
            layout="fullWidth"
            alt="Konstruktionen"
            loading="lazy"
            style={{ height: '100%' }}
            />
          </div>

        </PhotoGrid>
      </Container>

      <Container>
        <Text><b>Schrauben Vereinzelung<br />
        Kunde: Wälzlein-Schwabach<br />
        Vereinzelung von Spax-Schrauben der Länge 500mm</b><br /><br /></Text>
      <Text style={{ marginBottom: space[20] }}>
      Oben dargestellt ist ein Rütteltopf mit einem Durchmesser von 1000mm. 
      Die Vereinzelung der 500mm langen Spax-Schrauben findet durch definiertes 
      Fallen eine eine Schachtel statt. Außerdem verbaut ist ein Stückzahlenzähler zur Mengenbegrenzung.
      </Text>
      </Container>
      <Container style={{
          marginTop: space[20],
          backgroundColor: colors.pearlBush,
          padding: space[6],
        }}>
        <TwoColumnGrid>

          <div>
           <Text><b>Schalter Verschraubanlage<br />
            Kunde: Firma Sontheimer Elektrogeräte Schwabach<br />
            Vereinzelung von Spax-Schrauben der Länge 500mm</b></Text>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              border: colors.grey,
            }}
          >
            <div data-inviewport="fade-in-right">
              <StaticImage
                src="../images/beispiel-3.png"
                layout="constrained"
                objectFit="contain"
              />
            </div>
          </div>
        </TwoColumnGrid>
      </Container> 

      <Container style={{ marginTop: space[20]}}>
        <TwoColumnGrid>
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              border: colors.grey,
            }}
          >
            <div data-inviewport="fade-in-left">
              <StaticImage
                src="../images/beispiel-4.png"
                layout="constrained"
                objectFit="contain"
              />
            </div>
          </div>
          <div>
           <Text>Die Schalter werden durch einen Mitarbeitenden zugeführt, 
            anschließend werden vier Schrauben automatisch mittels zwei Schraubanlagen gesetzt, 
            die Schraubenzuführung ist von DEPRAG. Die Drehung erfolgt anhand eines pneumatischen Drehmoduls.
            </Text>
          </div>
        </TwoColumnGrid>
      </Container>  
      <Container style={{
          marginTop: space[20],
          backgroundColor: colors.pearlBush,
          padding: space[6],
        }} >
      <PhotoGrid>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRowEnd: 3,
              gridColumnEnd: 7,
            }}
          >
              <StaticImage
                src="../images/beispiel-5.png"
                layout="fullWidth"
                alt="Konstruktionen"
                loading="lazy"
                style={{ height: '100%' }}
              />
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 7,
              gridRowEnd: 3,
              gridColumnEnd: 13,
            }}
          >
            <StaticImage
            src="../images/beispiel-6.png"
            layout="fullWidth"
            alt="Konstruktionen"
            loading="lazy"
            style={{ height: '100%' }}
            />
          </div>

        </PhotoGrid>

        <div style={{ paddingBottom: space[10]}}>
          <Text><b>Deckel Verschraubstation für Gehäuse<br />
          Kunde: Firma Sontheimer Elektrogeräte Schwabach<br />
          UR 3 Unirobot mit HERGA Multigreifer</b><br /><br /></Text>
          <Text>
          Die Gehäuse werden mittels Förderband zugeführt, 
          abschließend werden automatisch zwei Schrauben 
          mittels einer Schraubanlage gesetzt, die Schraubenzuführung geschieht von DEPRAG.
          </Text>        
          </div>
      </Container>

      <Container style={{ marginTop: space[20]}}>
        <h2>Verschiedene Greifer</h2>
        <PhotoGrid>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRowEnd: 9,
              gridColumnEnd: 7,
            }}
          >
            <StaticImage
              data-inviewport="fade-in-left"
              src="../images/greifer-1.png"
              layout="constrained"
              alt="Greifer"
              loading="lazy"
              style={{ height: '100%' }}
            />
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 1,
              gridColumnStart: 7,
              gridRowEnd: 9,
              gridColumnEnd: 13,
            }}
          >

            <StaticImage
              src="../images/greifer-2.png"
              layout="fullWidth"
              alt="Greifer"
              loading="lazy"
              style={{ height: '100%' }}
            />
          </div>

          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 9,
              gridColumnStart: 1,
              gridRowEnd: 17,
              gridColumnEnd: 7,
              transitionDelay: '200ms',
            }}
          >
            <StaticImage
              src="../images/greifer-3.png"
              layout="fullWidth"
              alt="Greifer"
              loading="lazy"
              style={{ height: '100%' }}
            />
          </div>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 9,
              gridColumnStart: 7,
              gridRowEnd: 17,
              gridColumnEnd: 13,
              transitionDelay: '200ms',
            }}
          >   
            <StaticImage
              src="../images/greifer-4.png"
              layout="fullWidth"
              alt="Greifer"
              loading="lazy"
              style={{ height: '100%' }}
            />
          </div>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 18,
              gridColumnStart: 1,
              gridRowEnd: 17,
              gridColumnEnd: 7,
              transitionDelay: '200ms',
            }}
          >
            <StaticImage
                src="../images/greifer-5.png"
                layout="fullWidth"
                alt="Greifer"
                loading="lazy"
                style={{ height: '100%' }}
              />
          </div>
          <div
            data-inviewport="fade-in-bottom"
            style={{
              gridRowStart: 18,
              gridColumnStart: 7,
              gridRowEnd: 17,
              gridColumnEnd: 13,
              transitionDelay: '200ms',
            }}
          >
            <StaticImage
                src="../images/greifer-6.png"
                layout="fullWidth"
                alt="Greifer"
                loading="lazy"
                style={{ height: '100%' }}
              />
          </div>
        </PhotoGrid>
      </Container>

    </Page>
  )
}

export default ArbeitsBeispiele
